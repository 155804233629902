import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Selector from 'components/ui/Selector';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Calendar } from 'react-date-range';
import { EmployeeContext } from 'AppContexts';
import { getUTCDate } from 'utils';
import NoManagerAlert from './NoManagerAlert';
import AvailableDayOff from './AvailableDayOff';
import WithPermission from 'components/WithPermission';

const styles = (theme) => ({
	root: {
		margin: 0,
		width: '100%',
		padding: theme.spacing(3)
	},
	inputs: {
		'justify-content': 'center',
		margin: '14px 0 10px 0',
		padding: '0 0 25px 0'
	},
	inputs100w: {
		'& ,MuiInput-formControl': {
			width: '100%'
		}
	},
	dateRange: {
		'justify-content': 'center',
		margin: '10px 0 10px 0',
		padding: '0 0 25px 0'
	},
	manually_days: {
		width: '100%',
		display: 'flex',
		'justify-content': 'space-between',
		margin: '28px 0 0px 0'
	},
	content: {
		'max-width': '700px',
		display: 'inline-grid'
	},
	help: {
		'text-align': 'center',
		'font-size': '1em',
		'font-weight': 'bold',
		'margin-bottom': 0,
		'& .highlight': {
			color: theme.palette.primary.light
		}
	},
	'@global': {
		'.react-daterange-picker__wrapper': {
			border: 'none',
			'border-bottom': '1px solid rgba(0, 0, 0, 0.54)'
		},
		'.react-daterange-picker__range-divider': {
			'line-height': '3',
			width: '22px',
			'text-align': 'center'
		}
	},
	dialog: {
		height: '93vh',
		width: '100%'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	alert: {
		marginBottom: '25px'
	},
	calendarContainer: {
		width: '100%',
		'& .rdrMonth': {
		  width: '100%',
		},
		'& .rdrCalendarWrapper': {
		  width: '100%',
		  maxWidth: 'none',
		},
	}
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions);

class DaysClaimForm extends React.Component {
	static contextType = EmployeeContext;

	state = {
		type: 'compensation',
		state: 'on_review',
		amount: 0,
		employee: this.context._id,
		holidays_inside: 0,
		weekends_inside: 0,
		vacation_postponed_days: 0,
		vacation_current_days: 0,
		vacation_inadvance_days: 0,
		request_description: '',
		response_description: '',
		selectedDate: new Date()
	};

	onEnter = () => {
		if (this.props.claim) {
			const {
				holidays_inside,
				weekends_inside,
				employee,
				end_date,
				start_date,
				response_description,
				request_description,
				vacation_inadvance_days,
				vacation_current_days,
				vacation_postponed_days,
				state,
				amount,
				type
			} = this.props.claim;

			this.setState(
				{
					type,
					state,
					amount,
					employee: employee._id,
					holidays_inside: holidays_inside ? holidays_inside : '',
					weekends_inside: weekends_inside ? weekends_inside : '',
					vacation_postponed_days,
					vacation_current_days,
					vacation_inadvance_days,
					request_description,
					response_description,
					selectedDate: getUTCDate(start_date),
				}
			);
		}
	};

	handleSubmit = () => {
		const data = { ...this.state };
		const { claim } = this.props;

		data.start_date = this.state.selectedDate;
		data.end_date = this.state.selectedDate;

		if (claim) {
			data.id = claim._id;
		}

		delete data.selectedDate;
		this.props.onSubmit(data);
	};

	onChangeDescription = (e) => this.setState({ [e.target.name]: e.target.value });

	onChangeDays = (e) => {
		const key = e.target.name;
		const value = e.target.value;

		if (value >= 0) {
			this.setState({ [key]: value });
		}
	};

	onChangeType = (type) => {
		this.setState({ type });
	};

	onChangeState = (state) => this.setState({ state });

	handleDateChange = (date) => {
		this.setState({ selectedDate: date });
	};

	render() {
		const { open, onClose, classes, claim, availableDays } = this.props;
		const { request_description, state, response_description } = this.state;

		const isManagerReview =
			claim && claim.employee._id !== this.context._id && this.context.is_manager;
		const isManager = this.context.is_manager;
		const hasManager = this.context.manager_id;
		const specialEmployee = isManager && !hasManager;

		return (
			<Dialog
				onClose={onClose}
				aria-labelledby="dialog-title"
				open={open}
				maxWidth="xl"
				className={classes.root}
				TransitionProps={{
					onEnter: this.onEnter
				}}
			>
				<DialogTitle id="dialog-title" onClose={onClose}>
					{isManagerReview ? 'Review' : 'Request'} a Compensation Day
				</DialogTitle>

				<DialogContent dividers>
					<div className={classes.content}>
						{!isManagerReview && <p className={classes.help}>
							Choose a day to compensate for a non-working day you previously worked. You can select one day at a time, up to the number of days you've accumulated. Weekends don't count as days off, so you don't need to consider them when choosing your day.
						</p>}

						{isManagerReview ? <p className={classes.help}>
							Employee is taking off {this.state.selectedDate.toDateString()}
						</p>:
						<div className={classes.calendarContainer}>
							<Calendar
								date={this.state.selectedDate}
								onChange={this.handleDateChange}
								className={classes.inputs}
								color="#15A6D9"
								disabled={isManagerReview}
								showDateDisplay={false}
							/>
						</div>}

						{specialEmployee && <NoManagerAlert />}

						<WithPermission has={'days_off_view'}>
							<AvailableDayOff
								availableDays={availableDays}
								onClose={this.onCloseDaysOff}
								onOpenPostponeDaysInstructions={this.onOpenPostponeDaysInstructions}
								onOpenInadvanceDaysInstructions={this.onOpenInadvanceDaysInstructions}
							/>
						</WithPermission>

						{!isManagerReview && < div>
							<p className={classes.help}>
								Remember, Compensation days off get discounted from your accumulated.
							</p>
						</div>}

						<TextField
							className={classes.inputs}
							onChange={this.onChangeDescription}
							name="request_description"
							key="request_description"
							value={request_description}
							inputProps={{ autoComplete: 'off' }}
							label="Request description"
							multiline
							rows="6"
							fullWidth
							disabled={isManagerReview}
							placeholder="Please input here anything you consider important for processing your request appropriately, e.g.: national holidays or non-workable days within your chosen timespan, among other situations. Be as detailed as you can."
						/>

						{!isManagerReview && (
							<p className={classes.help}>
								If your request gets approved, but you end up not taking those days off,{' '}
								<span className="highlight">
									please remember to delete it in order to save those days for later
								</span>
							</p>
						)}

						<div>
							{isManagerReview && (
								<div>
									<Selector
										key="state_selector"
										name="state"
										value={state}
										onChange={this.onChangeState}
										options={{
											approved: 'Approved',
											declined: 'Declined'
										}}
									/>
									<p className={classes.help}>
										Manager: please review the request and decide wisely whether to approve or
										decline it.
									</p>

									<TextField
										className={classes.inputs}
										onChange={this.onChangeDescription}
										name="response_description"
										key="response_description"
										value={response_description}
										inputProps={{ autoComplete: 'off' }}
										label="Response description"
										multiline
										rows="6"
										fullWidth
										placeholder="Manager, please input here the reasons (if any) why the request was approved or declined, be as detailed as you can."
									/>
								</div>
							)}
						</div>
					</div>
				</DialogContent>

				<DialogActions>
					<Button autoFocus onClick={this.handleSubmit} color="primary">
						Save
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withStyles(styles)(DaysClaimForm);
